import type { ValuSearch } from "../index";
import {
    assertMainEntryNotLoaded,
    createEmitter,
    isSearchActive,
    loadPolyfill,
    loadScript,
} from "./lazy-utils";

interface SelectOptions {
    nullable?: boolean;
}

/**
 * document.querySelector() wrapper which asserts that the returned element
 * matches the given type
 *
 * @param selector CSS selector
 * @param klass Element subclass
 * @param options Allow nullable
 * @returns
 */
export function select<
    Klass extends typeof Element,
    Options extends SelectOptions,
>(
    selector: string,
    klass: Klass,
    options?: Options,
): Options extends { nullable: true }
    ? InstanceType<Klass> | null
    : InstanceType<Klass> {
    const el = document.querySelector(selector);

    if (el instanceof klass) {
        return el as InstanceType<Klass>;
    }

    if (options?.nullable) {
        // No idea how to actually narrow conditial return type here, hence casting...
        return null as any;
    }

    throw new Error(
        `[RVS] Bad selector "${selector}" for  "${klass.name}" got: "${String(
            el,
        )}`,
    );
}

/**
 * Simple wrapper for the DOMContentLoaded event which fires the callback when
 * bound after the actual event
 */
export function onDomContentLoaded(cb: () => any) {
    if (/complete|interactive|loaded/.test(document.readyState)) {
        cb();
    } else {
        const cbRemove = () => {
            cb();
            document.removeEventListener("DOMContentLoaded", cbRemove);
        };
        document.addEventListener("DOMContentLoaded", cbRemove, false);
    }
}

export interface LazyLoadedModule {
    default: ValuSearch;
}

export interface ValuSearchLoaderOptions<Module> {
    instanceId?: string;
    polyfill?: Parameters<typeof loadPolyfill>[1];
    load: () => Promise<Module>;
}

export interface OnLazyLoaded<Module> {
    (vs: ValuSearch, module: Module): any;
}

declare global {
    interface Window {
        valuSearchLoader?: LazyValuSearch<any>;
    }
}

export class LazyValuSearch<Module extends LazyLoadedModule> {
    module?: Module;

    private options: ValuSearchLoaderOptions<Module>;

    private onLoad = createEmitter<[vs: ValuSearch, module: Module]>();

    replaced?: boolean;

    instanceId: string;

    constructor(options: ValuSearchLoaderOptions<Module>) {
        this.instanceId = options.instanceId || "vs";
        this.options = options;

        if (typeof window !== undefined) {
            const wasReplaced = window.valuSearchLoader?.replaced || false;

            window.valuSearchLoader = this;

            if (!wasReplaced) {
                const url = localStorage.valuSearchLoaderReplaced;
                if (url) {
                    this.replaced = true;
                    loadScript(url);
                }
            }
        }
    }

    /**
     * triggers loader.load() if the input has focus when this is called,
     * otherwise adds onfocus eventlistener to input that calls loader.load()
     * @param input HTMLInputElement
     */
    loadOnFocus(input: HTMLInputElement) {
        if (document.activeElement === input) {
            this.load();
        } else {
            input.addEventListener("focus", () => {
                this.load();
            }, {once: true});
        }
    }

    isActive = () => {
        return isSearchActive(this.options.instanceId);
    };

    /**
     * Returns true when the module has be loaded
     */
    isLoaded = () => {
        return !!this.module;
    };

    /**
     * Class name used to create FocusTrap containers
     */
    get trapClassName() {
        return `valu-search-focus-trap-${this.instanceId}`;
    }

    replace = (url: string) => {
        localStorage.valuSearchLoaderReplaced = url;
        window.location.reload();
    };

    load = () => {
        if (this.replaced) {
            return;
        }

        assertMainEntryNotLoaded();

        loadPolyfill(() => {
            if (this.module) {
                return this.onLoad.emit(this.module.default, this.module);
            }

            this.options.load().then(
                (mod) => {
                    // Add a good error message for non-typescript users
                    if (
                        !mod.default ||
                        typeof mod.default.initModal !== "function"
                    ) {
                        throw new Error(
                            "[RVS] ValuSearchLoader: Invalid default export from the lazy loaded module. Expected ValuSearch instance.",
                        );
                    }

                    if (this.instanceId !== mod.default.instanceId) {
                        throw new Error(
                            `[RVS] The lazy loaded RVS instanceId "${mod.default.instanceId}"  does not match with loader instanceId "${this.instanceId}"`,
                        );
                    }

                    this.module = mod;
                    this.onLoad.emit(this.module.default, this.module);
                },
                (error) => {
                    console.error(
                        "[RVS] ValuSearchLoader: Failed to load",
                        error,
                    );
                },
            );
        }, this.options.polyfill);
    };

    activate = () => {
        this.onLoad.once((vs) => {
            vs.activate();
        });
        this.load();
    };

    init = (onInit: () => OnLazyLoaded<Module> | undefined | void) => {
        if (this.replaced) {
            return;
        }

        onDomContentLoaded(() => {
            const onLazyLoaded = onInit();
            if (onLazyLoaded) {
                this.onLoad.once(onLazyLoaded);
            }

            if (isSearchActive(this.options.instanceId)) {
                this.load();
            }
        });
    };
}
